import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap, take } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "purchase-order-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderUpdateComponent
	extends GenericUpdateComponent<IPurchaseOrder>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Purchase Order name already in use.",
				warning_translation_key: marker(
					"form_field_warning_purchase_order_name_already_in_use",
				),
			},
			po_number: {
				type: "string",
				title: "PO Number",
				title_translation_key: marker("form_field_label_po_number"),
			},
			po_date: {
				type: "string",
				title: "PO Date",
				title_translation_key: marker("form_field_label_po_date"),
				widget: "date",
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
			is_outgoing: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
			},
		},
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	purchase_order$ = this._store.select(fromDashboard.getSelectedPurchaseOrder);
	purchase_order: IPurchaseOrder;

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Purchase Order";
		this.form_title_translation_key = marker("form_title_update_purchase_order");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.purchase_order$
			.pipe(isNotNullOrUndefined(), take(1))
			.subscribe((purchase_order) => {
				this.purchase_order = purchase_order;
				this.model.id = this.purchase_order.id;
				this.model.name = this.purchase_order.name;
				this.model.po_number = this.purchase_order.po_number;
				this.model.po_date = this.purchase_order.po_date;
				this.model.is_outgoing = this.purchase_order.is_outgoing;
				this.model.archived = this.purchase_order.archived;
				if (purchase_order.is_outgoing) {
					this.form_title = "Update Shipping Order";
					this.form_title_translation_key = marker(
						"form_title_update_shipping_order",
					);
					this.schema.properties.po_date.title = "Shipping Date";
					this.schema.properties.po_date.title_translation_key = marker(
						"form_field_label_shipping_date",
					);
					this.schema.properties.po_number.title = "Shipping number";
					this.schema.properties.po_number.title_translation_key = marker(
						"form_field_label_shipping_number",
					);
					this.schema.properties.name.warning =
						"Warning: Shipping Order name already in use.";
					this.schema.properties.name.warning = marker(
						"form_field_warning_shipping_order_name_already_in_use",
					);
				}
				this._cd.detectChanges();
			});

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`purchase_orders/fetch/names`, "", {
				col: "name",
				except: this.purchase_order.name,
				is_outgoing: this.purchase_order.is_outgoing.toString(),
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});

		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IPurchaseOrder>) {
		const updated_po: Partial<IPurchaseOrder> = {
			id: this.purchase_order.id,
			name: type.name || undefined,
			po_number: type.po_number || undefined,
			po_date: type.po_date || undefined,
			archived: type.archived,
		};

		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order`,
				Number(type.id),
				updated_po,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
